* {
  outline: none;
  outline: 0;
}

//.app-full-bleed-dialog {
//  mat-dialog-container {
//    background: transparent;
//    position: relative;
//    margin: 0;
//    padding: 0;
//    overflow: visible;
//  }
//}

.app-full-bleed-dialog {
  background: #102a41;
  border-radius: 4px;
  max-width: 80vw !important;;
}

.hidden {
  display: none;
}
