/* MegaNavbar v2.2.0 (http://www.MegaNavbar.com) Copyright 2014 MegaNavbar.com. All Rights Reserved.
** Licensed under Regular License (http://codecanyon.net/licenses/regular) or Extended License (http://codecanyon.net/licenses/extended)
** We will take legal action against those who copy our HTML content, CSS style sheets and JavaScript functions without a license.
*/

/* Please, comment files with animation below, which you don't use on your page */
/*@import url('speed.css');*/
/*@import url('hinge.css');*/
/*@import url('slide.css');*/
/*@import url('bling.css');*/
/*@import url('bounce.css');*/
/*@import url('skew.css');*/
/*@import url('space.css');*/
/*@import url('scale.css');*/
/*@import url('zoom.css');*/
/*@import url('rotate.css');*/
/*@import url('flip.css');*/

/* Animation settings*/
@media(min-width:917px){
	.navbar .navbar-nav[data-duration="ultrafast"] .dropdown-menu,.navbar[data-duration="ultrafast"] .navbar-nav .dropdown-menu,.navbar .navbar-nav .dropdown-menu[data-duration="ultrafast"]{-webkit-animation-duration:150ms;-moz-animation-duration:150ms;-o-animation-duration:150ms;animation-duration:150ms}
	.navbar .navbar-nav[data-duration="fast"] .dropdown-menu,.navbar[data-duration="fast"] .navbar-nav .dropdown-menu,.navbar .navbar-nav .dropdown-menu[data-duration="fast"]{-webkit-animation-duration:250ms;-moz-animation-duration:250ms;-o-animation-duration:250ms;animation-duration:250ms}
	.navbar .navbar-nav .dropdown-menu,.navbar .navbar-nav[data-duration="normal"] .dropdown-menu,.navbar[data-duration="normal"] .navbar-nav .dropdown-menu,.navbar .navbar-nav .dropdown-menu[data-duration="normal"]{-webkit-animation-duration:500ms;-moz-animation-duration:500ms;-o-animation-duration:500ms;animation-duration:500ms}
	.navbar .navbar-nav[data-duration="slow"] .dropdown-menu,.navbar[data-duration="slow"] .navbar-nav .dropdown-menu,.navbar .navbar-nav .dropdown-menu[data-duration="slow"]{-webkit-animation-duration:750ms;-moz-animation-duration:750ms;-o-animation-duration:750ms;animation-duration:750ms}
	.navbar .navbar-nav[data-duration="superslow"] .dropdown-menu,.navbar[data-duration="superslow"] .navbar-nav .dropdown-menu,.navbar .navbar-nav .dropdown-menu[data-duration="superslow"]{-webkit-animation-duration:1000ms;-moz-animation-duration:1000ms;-o-animation-duration:1000ms;animation-duration:1000ms}
	.navbar .navbar-nav[data-duration="ultraslow"] .dropdown-menu,.navbar[data-duration="ultraslow"] .navbar-nav .dropdown-menu,.navbar .navbar-nav .dropdown-menu[data-duration="ultraslow"]{-webkit-animation-duration:1500ms;-moz-animation-duration:1500ms;-o-animation-duration:1500ms;animation-duration:1500ms}
	.navbar:not(.dropdown-onhover) .navbar-nav:not(.dropdown-onhover)>li:not(.dropdown-onhover):not(.open)>.dropdown-menu,.navbar:not(.dropdown-onhover) .navbar-nav:not(.dropdown-onhover)>li:not(.dropdown-onhover):not(.open)>.dropdown-grid-wrapper>.dropdown-menu,.navbar.dropdown-onhover.no-fix .navbar-nav>li:not(:hover)>.dropdown-menu,.navbar.dropdown-onhover.no-fix .navbar-nav>li:not(:hover)>.dropdown-grid-wrapper>.dropdown-menu,.navbar.dropdown-onhover:not(.no-fix) .navbar-nav>li:not(.open):not(:hover)>.dropdown-menu,.navbar.dropdown-onhover:not(.no-fix) .navbar-nav>li:not(.open):not(:hover)>.dropdown-grid-wrapper>.dropdown-menu,.navbar .navbar-nav.dropdown-onhover.no-fix>li:not(:hover)>.dropdown-menu,.navbar .navbar-nav.dropdown-onhover.no-fix>li:not(:hover)>.dropdown-grid-wrapper>.dropdown-menu,.navbar .navbar-nav>li.dropdown-onhover.no-fix:not(:hover)>.dropdown-menu,.navbar .navbar-nav>li.dropdown-onhover.no-fix:not(:hover)>.dropdown-grid-wrapper>.dropdown-menu,.navbar .navbar-nav.dropdown-onhover:not(.no-fix)>li:not(.open):not(:hover)>.dropdown-menu,.navbar .navbar-nav.dropdown-onhover:not(.no-fix)>li:not(.open):not(:hover)>.dropdown-grid-wrapper>.dropdown-menu,.navbar .navbar-nav>li.dropdown-onhover:not(.no-fix):not(.open):not(:hover)>.dropdown-menu,.navbar .navbar-nav>li.dropdown-onhover:not(.no-fix):not(.open):not(:hover)>.dropdown-grid-wrapper>.dropdown-menu{-moz-animation-name:none;-o-animation-name:none;animation-name:none;-webkit-animation-name:none}
	.navbar .navbar-nav li[class*="collapse-"]>.dropdown-menu,.navbar .navbar-nav li[class*="-onhover"]:not(.no-fix):not(:hover)>.dropdown-menu:not(.in):not(.collapsing),.navbar .navbar-nav li[class*="-onhover"].no-fix:not(:hover)>.dropdown-menu:not(.collapsing),.navbar .navbar-nav li[class*="-onclick"]>.dropdown-menu.collapse:not(.in):not(.collapsing),.navbar .navbar-nav .no-animation .dropdown-menu,.navbar .navbar-nav .dropdown-menu.no-animation{-moz-animation-name: none!important;-o-animation-name: none!important;animation-name: none!important;-webkit-animation-name: none!important}
}
