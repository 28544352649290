@use '@angular/material' as mat;
@import '@angular/cdk/overlay-prebuilt.css';



$grassrootlabs-latest-primary: mat.$green-palette;
$grassrootlabs-latest-accent: mat.$green-palette;
$grassrootlabs-latest-warn: mat.$green-palette;

$grassrootlabs-latest-theme: mat.define-theme(
    (
      color: (
        primary: $grassrootlabs-latest-primary,

      ),
typography: (
        plain-family:  "Montserrat-Regular",
    )
));




@import "./app";
@import "./blog";
@import "bootstrap4";
@import './bootstrap.min';
@import './MegaNavbar';
@import './animation';
@import "@ng-select/ng-select/themes/default.theme.css";

.ng-select.ui-select.form-control {
  border:0px;
  min-height: 0px;
  padding: 0;
}

.ng-select.ui-select.form-control .ng-arrow-wrapper .ng-arrow {
  border-color: white transparent transparent !important;
}

.ng-select.ui-select.form-control .ng-select-container  {
  min-height: 0;
  margin-top: 3px;
  background: inherit !important;
  border: 0;
}

.ng-select.ui-select.form-control .ng-value-container {
  color: white !important;
  * {
    color: white !important;
  }
}

.radius-round-ltb {
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
