.blog {
	margin: 30px 0;
}

.blog-card {
	position: relative;

	padding: 20px;

	-webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
}

.blog-card:hover {
	-webkit-box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.3);
	box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.3);
}

.blog-card__title {
	color: #337ab7;
	text-align: center;
	font-weight: 300;
}

.blog-card__notify {
	text-align: right;
}

.blog-card__load {
	margin-top: 30px;
	text-align: center;
}

.blog-card__close {
	position: absolute;

	top: 10px;
	right: 15px;

	color: #0aadbc;
}

.notify-icons {
	margin: 0;
	padding: 0;

	display: inline-block;

	list-style-type: none;
}

.notify-icons:after {
	content: "";
	display: block;
	clear: both;
}

.notify-icons__item {
	float: left;

	margin-left: 5px;

	color: #1fbfd1;
}

.notify-icons__item:first-child {
	margin-left: 0;
}

.notify-icons__count {
	font-size: 12px;
}

.pointer {
	cursor: pointer;
}

.comments {
	margin-top: 15px;
	padding: 15px;

	background: #f4f4f4;

	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;

}

.comments__avatar {
	float: left;

	width: 74px;
	height: 74px;

	overflow: hidden;

	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.comments__avatar img {
	width: 74px;
	height: 74px;
}

.comments__textarea {
	position: relative;

	width: 100%;

	border: none;

	padding: 5px;

	background: #fff;
}

.comments__add {
	margin-bottom: 15px;
}

.comments__item {
	margin-bottom: 15px;
}

.comments__comment {
	position: relative;

	margin-left: 94px;
	padding: 20px;

	background: #fff;

	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;

	-webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
}

.comments__comment--textarea {
	padding: 5px;
}

.comments__comment:after {
	border-width: 10px 10px 10px 0;
	border-style: solid;
	border-color: transparent #e7e7e7;
	content: "";
	left: -10px;
	position: absolute;
	top: 20px;
}