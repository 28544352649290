@use '@angular/material' as mat;

$font-family: 'Montserrat-Regular';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "./styles/custom";
// Plus imports for other components in your app.

// @include mat-base-typography($custom-typography);
/* Grassroots Style - Dogan Kutbay */
/* latin */
.mat-mdc-dialog-surface{
  border-radius: 0 !important;
  box-shadow: none !important;
}
$custom-typography2: mat-typography-config(
  "Montserrat-Regular",
);

* {
  letter-spacing: normal !important;
}

html{
  --mdc-dialog-container-color: transparent;
}

.text-danger {
  color: #a94442 !important;
}

.text-success {
  color: #3c763d !important;
}

.mdc-text-field--filled{
  background-color: transparent !important;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat-Regular'), url(assets/fonts/montserrat-regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Work Sans Light'), local('WorkSans-Light'), url(assets/fonts/worksans-light.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Work Sans'), local('WorkSans-Regular'), url(assets/fonts/worksans.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

html, body{
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

* {
  outline: none;
  outline: 0;
}
.no-padding{
  padding: 0;
}

/* borderless table */
.table-borderless tbody tr td,
.table-borderless tbody tr th,
.table-borderless thead tr th,
.table-borderless thead tr td,
.table-borderless tfoot tr th,
.table-borderless tfoot tr td {
  border: none;
}
/* Switch Style - Toggle - Flip */
.tgl-list {
  float: left;
  width: auto;
  margin-right: 5px;
}

.tg-list-item {
  margin: 0 2em;
}

.tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
  box-sizing: border-box;
}
.tgl::-moz-selection, .tgl:after::-moz-selection, .tgl:before::-moz-selection, .tgl *::-moz-selection, .tgl *:after::-moz-selection, .tgl *:before::-moz-selection, .tgl + .tgl-btn::-moz-selection {
  background: none;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
  background: none;
}
.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 100px;
  height: 33px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  left: 50%;
}

.tgl-flip + .tgl-btn {
  padding: 2px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-family: sans-serif;
  -webkit-perspective: 100px;
  perspective: 100px;
}
.tgl-flip + .tgl-btn:after, .tgl-flip + .tgl-btn:before {
  display: inline-block;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 33px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 4px;
}
.tgl-flip + .tgl-btn:after {
  content: attr(data-tg-on);
  background: #02C66F;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.usertoggle .tgl-flip + .tgl-btn::before {
  background: #D9534F;
}
.usertoggle .tgl-flip:checked + .tgl-btn::after {
  background: #1FBFD1;
}
.tgl-flip + .tgl-btn:before {
  background: #F0AD4E;
  content: attr(data-tg-off);
}
.tgl-flip + .tgl-btn:active:before {
  -webkit-transform: rotateY(-20deg);
  transform: rotateY(-20deg);
}
.tgl-flip:checked + .tgl-btn:before {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.tgl-flip:checked + .tgl-btn:after {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
  left: 0;
  background: #7FC6A6;
}
.tgl-flip:checked + .tgl-btn:active:after {
  -webkit-transform: rotateY(20deg);
  transform: rotateY(20deg);
}
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}
.row-eq-height .well{
  height:100%;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert{
  font-size:0.8em;
  padding:7px;
}
a, .btn{
  -webkit-transition: all .2s ease-in;
  -moz-transition: all .2s ease-in;
  -ms-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  transition: all .2s ease-in;
}
a:hover {
  text-decoration: none;
  cursor: pointer;
}
.btn-default{
  color: #487cab;
  background-color: #ccecfc;
  border-color: transparent;
}
.btn-default:hover , .btn-primary:focus, .btn-primary:active{
  background-color: #99dbf8;
  border-color: transparent;
  color: #487cab;
}

.btn-primary {
  background-color: #1fbfd1;

  color: #FFFFFF;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary.active:hover, .btn-primary.active:focus {
  background-color: #0aadbc;
  border-color: #0b8792;
  color: #FFFFFF;
}

.top {
  height: 150px;
  line-height: 150px;
}

.admin {
  height: 80px;
  line-height: 80px;
  display: flex;
}
.top .img-responsive{
  display: initial;
}
.top10{
  margin-top:10px;
}
.bot10{
  margin-bottom:10px;
}
.top20{
  margin-top:20px;
}
.bot20{
  margin-bottom:20px;
}
.vcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.top .info{
  padding: 50px 50px 0 0;
}
.top .info  p{
  width: 70%;
  line-height: 1.2em;
  float: right;
  text-align: right;
  padding-right: 20px;
  padding-top: 5px;
}
.top .info i{
  width: 45px;
  height: 45px;
  border-radius: 45px;
  border: 1px solid #ddd;
  line-height: 45px;
  float: right;
  text-align: center;
}


.main-navigation{
  border-top:1px solid #f0f0f0;
  border-bottom:6px solid #f0f0f0;
  font-weight: 300;
}
.navbar {
  position: relative;
  height: 75px;
  margin: 0 -15px;
  border: none;
}
.navbar .dropdown-menu{
  top: calc(100% + 6px);
}

.navbar-nav > li > a {
  line-height: 75px;
  padding: 0 10px;
  text-transform: uppercase;
}

.navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  color: #555;
  background:transparent;
}
.navbar .navbar-nav .dropdown-menu li a:hover {
  background: #ccecfc;
  border-radius: 3px;
}
.navbar-nav>.active:after, .nav .open>a:after{
  content: "";
  display: block;
  height: 6px;
  background: #487cab;
  width: 100%;
  left: 0;
  bottom: -6px;
  position: absolute;
}
.navbar:not(.no-border) .navbar-nav>li.dropdown-grid>.dropdown-grid-wrapper>.dropdown-menu, .navbar:not(.no-border) .navbar-nav>li[class*=dropdown]>.dropdown-menu {
  margin-top: 0;
  margin-bottom: 1px;
}

/*Carousel modifications */
.carousel-control.right, .carousel-control.left{
  background: none;
}
.carouselcontainer {
  /*background:#102a41; old color*/
  background: #0aadbc;
  color: white;
}
.carouselcontainer h3{
  margin: 0;
  padding-top: 30px;
  line-height: 1;
  color: white;
}

.carouselcontainer h1{
  position: absolute;
  top: 10px;
  height: 30px;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 2.500em ;
}
.carouselcontainer h2{
  color: white;
  font-family: "Work Sans",serif;
  font-weight: 300;
  font-size: 1.553em;
  text-transform: uppercase;
}
.carouselcontainer p{
  font-size: 0.9em;
}
.slide{
  height: 300px;
  margin-top: 50px;
  padding: 0 20px;
}
.slide .image{
  float: left;
  width: 10%;
  height: 350px;
  padding-top: 50px;
}


@-webkit-keyframes smooth {
  0% { opacity: 0;}
  100% { opacity: 1;}
}

.slide .texts{
  width: calc(90% - 20px);
  margin-left: 110px;
  text-align: left;
}
.slide .btn{
  position: relative;
  z-index: 1;
  float: left;
  color: #487cab;
  text-transform: uppercase;
  font-size: 0.938em;
}
.btn-deepblue{
  border: 1px solid #153755;
}
.btn-deepblue:hover{
  background-color: #04182a;
}
ol.carousel-indicators{
  z-index: 14;
  bottom: 10px;
}
.carousel-indicators li {

  display: inline-block;
  width: 20px;
  height: 7px;
  margin: 0 5px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #1fbfd1;
  border-radius: 4px;
  border: none;
}
.carousel-indicators .active {
  width: 20px;
  height: 7px;
  margin: 0 5px;
  background-color: #ccecfc;
}

.mainvideo{
  background: #12c5d6;
  margin:10px 0;
}

.mainvideo h1{
  color: white;
}

.seeoursystem h3{
  font-size:2.3em;
  margin-top:20px;
}

.removeborder{
  transform: scale(1.01);
}

.explanation {
  margin:10px auto;
}

.explanation .arrowright:after {
  left: calc(100% - 15px);
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #e3e3e3;
  border-width: 23px;
  margin-top: -38px;
}
.explanation h2{
  text-align: center;
  font-size:2.500em;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 15px;
}
.explanation h3{
  text-align: center;
  font-size:2.500em;
  border-bottom: 6px solid #f0f0f0;
  padding-bottom: 15px;
}
.explanation h4{
  text-align: center;
  font-size:1.5em;
  padding-bottom: 15px;
  text-transform: uppercase;
}
.explanation .video p{
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.explanation img{
  box-shadow: 0 5px 15px rgba(0,0,0,.4);
}
.well img{
  box-shadow:none;
}

.youtube-page{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.88);
  z-index: 9;
  opacity:0;
  pointer-events: none;
  -webkit-transition: opacity 450ms ease;
  -moz-transition: opacity 450ms ease;
  -ms-transition: opacity 450ms ease;
  -o-transition: opacity 450ms ease;
  transition: opacity 450ms ease;
}
.youtube-page.active{
  opacity:1;
  pointer-events:auto;
}

.youtube-page .actual-video{
  position: relative;
  top: -1000px;;
  left:25%;
  background:white;
  width: 50%;
}

.youtube-page.active .actual-video{
  top:100px;
  transition:top 250ms ease .3s;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0,0,0,.4);
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

.referral{
  background:#1c629e;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  border-top: 3px solid rgba(255,255,255,.19);
  border-bottom: 3px solid rgba(255,255,255,.19);
  padding: 10px 0;
  color: white;
}
.referral i{
  font-size: 3em;
}
.referral h5{
  font-size: 2.300em;
  color: white;
}

.contact{
  background:#102a41;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  border-top: 3px solid rgba(255,255,255,.19);
  border-bottom: 3px solid rgba(255,255,255,.19);
  padding: 10px 0;
  color: white;
  line-height: 30px;
  font-size: 1.4em;
}
.contact i{
  margin-right: 10px;
}
.contact p{
  margin: 0;
}
.footer{
  background:#071623;
  line-height: 50px;
  min-height: 60px;
  padding-top: 10px;

}
.footer p.copyright{
  margin: 0;
  color: white;
  text-transform: uppercase;
}
.footer .social i{
  margin-top: 10px;
  font-size: 2em;
}

.pagination-search{
  float: left;
  width:100%;
  margin-bottom:10px;
  /*border-bottom:1px solid rgba(0,0,0,.1);*/
}
/* for popups in general */
.cancel{
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 9px;
  padding:0 10px;
  border-radius: 10px;
  background: #ccecfc;
  text-align: center;
  line-height:30px;
  color: #102a41;
  cursor: pointer;
  -webkit-transition: all .2s ease-in;
  -moz-transition: all .2s ease-in;
  -ms-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  transition: all .2s ease-in;
}
.cancel:hover{
  background:#106c9a;
  color: white;
}
.popup h1{
  padding-bottom:10px;
  margin-bottom:10px;
  text-align: center;
  border-bottom:1px solid #183b5a;
  text-transform: uppercase;
  font-size:1.2em;
  color: #ccecfc;
}
.popup .input-group {
  margin:0;
}

/* Warning Popups */
.warningPop{
  background:#ff706c;
  padding:20px;
  border-radius:4px;
}
.warningPop h2{
  padding: 0;
  margin: 0 0 8px 0;
  font-size: 19px;
  text-align: center;
  color: #6d2a28;
  white-space: pre-wrap;
  line-height: 28px;
}
/* for any kind of popup forms */
.forms{
  padding:24px;
  position: relative;
  background:#102a41;
  color: white;
  border-radius:4px;
}
@media (max-width: 917px) {
  .forms {
    padding: 0;
  }
}
.cdk-overlay-pane {
  overflow: auto;
  app-full-bleed-dialog modal-sm {
    pointer-events: auto;
    width: 300px;
  }
}

.forms input::-webkit-input-placeholder {
  color: white !important;
}

.forms input:-moz-placeholder { /* Firefox 18- */
  color: white !important;
}

.forms input::-moz-placeholder {  /* Firefox 19+ */
  color: white !important;
}

.forms input:-ms-input-placeholder {
  color: white !important;
}
.mat-dialog-container{
  box-shadow: none;
  transform: none;
  height: -webkit-fill-available;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
  background: none;
}
.mat-dialog-container{
  display: inline-table !important;
}
.forms .logo{
  text-align: center;
  margin-bottom:5px;
}
.forms .logo img{
  height:60px;
}

.forms label{
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size:0.60em;
  text-transform: uppercase;
}
.forms .checkbox label{
  line-height: 2.1em;
}

.forms .form-control{
  background: #1c629e !important;
  color: white !important;
  border:none !important;
  -webkit-transition: all .2s ease-in;
  -moz-transition: all .2s ease-in;
  -ms-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  transition: all .2s ease-in;
}
.forms .form-control:focus{
  background: white !important;
  color: #1c629e !important;
}
.forms a{
  font-size:0.9em;
  margin-left:13px;
}
.ui-select-bootstrap .ui-select-choices-row>a {
  margin-left:0;
}
.forms a:hover{
  color: white;
}

/* Table Fix */
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  vertical-align: middle;
}



.remove i:hover{
  color: red;
  cursor:pointer;
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -ms-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear;
}
/************* Checkbox and Radio button stylings *************/
.checkbox {
  padding-left: 20px; }
.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px; }
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -19px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: -1px;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #1463a9;
}
.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  opacity: 0;
  z-index: 1; }
.checkbox input[type="checkbox"]:focus + label::before,
.checkbox input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }
.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
  font-family: "FontAwesome";
  content: "\f00c";}
.checkbox input[type="checkbox"]:indeterminate + label::after,
.checkbox input[type="radio"]:indeterminate + label::after {
  display: block;
  content: "";
  width: 10px;
  height: 3px;
  background-color: #555555;
  border-radius: 2px;
  margin-left: -16.5px;
  margin-top: 7px;
}
.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
  opacity: 0.65; }
.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed; }
.checkbox.checkbox-circle label::before {
  border-radius: 50%; }
.checkbox.checkbox-inline {
  margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #337ab7;
  border-color: #337ab7; }
.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f; }
.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de; }
.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }
.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c; }
.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
  color: #fff;}

.checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #337ab7;
  border-color: #337ab7;
}

.checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}

.checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}

.checkbox-info input[type="checkbox"]:indeterminate + label::before,
.checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:indeterminate + label::after,
.checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}

.checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}

.checkbox-success input[type="checkbox"]:indeterminate + label::before,
.checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:indeterminate + label::after,
.checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}
.checkbox.inTests{
  margin-right:20px;
  display: inline-block;
}
.radio {
  padding-left: 20px; }
.radio label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px; }
.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 14px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out; }
.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 10px;
  height: 10px;
  left: 2px;
  top: 2px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #555555;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
.radio input[type="radio"] {
  opacity: 0;
  z-index: 1; }
.radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }
.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1); }
.radio input[type="radio"]:disabled + label {
  opacity: 0.65; }
.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed; }
.radio.radio-inline {
  margin-top: 0; }

.radio-primary input[type="radio"] + label::after {
  background-color: #337ab7; }
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #337ab7; }
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #337ab7; }

.radio-danger input[type="radio"] + label::after {
  background-color: #d9534f; }
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f; }
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f; }

.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de; }
.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de; }
.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de; }

.radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e; }
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e; }
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e; }

.radio-success input[type="radio"] + label::after {
  background-color: #5cb85c; }
.radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c; }
.radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c; }

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
  font-family: 'FontAwesome', arial, sans-serif;
  content: "\f00c"; }
input[type="checkbox"] .styled:checked + label::before,
input[type="radio"] .styled:checked + label::before {
  color: #fff; }
input[type="checkbox"] .styled:checked + label::after,
input[type="radio"] .styled:checked + label::after {
  color: #fff; }

/* Breadcrumbs */
.breadcrumbs{
  margin:30px 0;
}

.btn-breadcrumb .btn:not(:last-child):after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid white;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: 100%;
  z-index: 3;
}
.btn-breadcrumb .btn:not(:last-child):before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid rgb(173, 173, 173);
  position: absolute;
  top: 50%;
  margin-top: -17px;
  margin-left: 1px;
  left: 100%;
  z-index: 3;
}

/** The Spacing **/
.btn-breadcrumb .btn {
  padding:6px 12px 6px 24px;
}
.btn-breadcrumb .btn-default{
  background: white;
}
.btn-breadcrumb .btn:first-child {
  padding:6px 6px 6px 10px;
}
.btn-breadcrumb .btn:last-child {
  padding:6px 18px 6px 24px;
}

/** Default button **/
.btn-breadcrumb .btn.btn-default:not(:last-child):after {

  border-left: 10px solid #fff;
}
.btn-breadcrumb .btn.btn-default:not(:last-child):before {
  border-left: 10px solid #ccc;
}




/* Categories */
.pricelist{
  font-size: 0.8em;
}
.categories{
  margin-bottom:20px;
}
.categories h1{
  color: #0aadbc;
  border-bottom:2px solid rgba(0,0,0,.1);
}
.categories .product{
  float: left;
  border-bottom: 1px solid #99dbf8;
  height: 80px;
  width: 100%;
}
.categories .product h4{
  margin-top:15px;
}
.categories .product a:hover h4{
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  -ms-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s linear;
  color: #0aadbc;
}
.categories .cart{
  line-height:80px;
}
.userPart{
  //width:170px;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  padding: 7px 10px;
}

.userDrop{
  width: 100%;
}
/* Product Page */
.prices{
  font-size: 14px;
  line-height:30px;
  text-transform: uppercase;
  font-weight: bold;
}
.prices .old-price span{
  text-decoration: line-through;
  color: red;
}
.prices .savings-price span{
  color: green;
}
.productDescription{

  font-size: 1.1em;
  letter-spacing: -0.03em;
}
.standartInfo{
  margin:10px 0;
  border-top:1px solid #d6eae9;
  /*border-bottom:1px solid #d6eae9;*/
  padding:10px 0;
}
.standartInfo p b{
  float: left;
  line-height:30px;
  width:100%;
  font-weight:bolder;
}
.standartInfo .social {
  text-align:center;
  font-size: 2em;
}

.productDescription li{
  margin:0 0 0 20px;
  list-style: none;
}
.productDescription li:before {
  font-family:"FontAwesome", arial,sans-serif;
  content: "\f0da";
  color: #d6eae9;
  margin-right: 10px;
}

/* Search */
.search{
  width: 290px;
  height: 70px;
  position: absolute;
  z-index: -9999;
  left: 0;
  top: 0;
  opacity:0;
  background: red;
}
.search.expand {
  opacity:1;
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.7);
  padding: 20px;
  z-index: 9999;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}
.search .innerSearch {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
.search.expand .innerSearch {
  left: 20px;
  top: 20px;
  bottom: 20px;
  right: 20px;
  background: rgba(255,255,255,0.9);
  box-shadow: 0 0 9px 6px rgba(0,0,0,0.11);
  padding: 50px 120px;
  border-radius:10px;
}
.innerSearch .search-input{
  width: auto;
  float: left;
  height: 70px;
  border: none;
  background: none;
  color: #999;
  font-size: 19px;
  font-weight: 300;
}
.search.expand .innerSearch .search-input{
  width: 100%;
  height: 100px;
  font-size: 70px;
  letter-spacing: -1px;
  outline: none;
  color: #5173c1;
  padding-right: 110px;
}
.search .cancel{
  top: 12px;
  right: 13px;
  background: #08d1ff;
  color: #ffffff;
}

.search .search-button {
  width: 70px;
  height: 70px;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  background: url("assets/img/search-button-bg.png") center center;
  background-size: 100% 100%;
}

.search.expand .search-button{
  width: 100px;
  height: 100px;
  position: absolute;
  right: 120px;
  outline: none;
  top: 50px;
}
.search .search-button:hover{
  background-color:white;
  -webkit-transition: all .5s linear;
  -moz-transition: all .5s linear;
  -ms-transition: all .5s linear;
  -o-transition: all .5s linear;
  transition: all .5s linear;
  border-radius:10px;
}

.searchResults{
  width: 100%;
  height: 90%;
  float: left;
  position: relative;
  /*overflow: overlay;*/
  margin:20px 0;
}
.searchResults h2{
  margin:0;
  padding:0;
}
.searchResult{
  margin-top:10px;
}

.searchResult h2{
  padding-bottom: 10px;
  border-bottom:2px solid rgba(0,0,0,.1);
}
.searchResult .product {
  border:none;
}


.cabinet label{
  font-size: 0.8em;
}
.cabinetTabs{
  margin-bottom:20px;
  margin-top:-1px;
  border-radius:3px;
  border:1px solid #ddd;
  padding-top:10px;
  padding-right:15px;
  padding-left:15px;
}
.table-responsive {
  overflow-x: initial;
}

/*ngscrollbar style*/
.ngscroll-scrollbar-container{
  height: 14px !important;
  background: rgba(0,0,0,.1) !important;
}
.ngscroll-scrollbar{
  height: 10px !important;
  opacity: 1 !important;
  bottom: 2px !important;
}
.ngscroll-scrollbar:hover{
  cursor: pointer !important;
}
.addressIndicator{
  background: #f7f7f7;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  padding: 0 10px 0 0;
  margin-bottom: 10px;
}



ul.panels li ul.dropdown-menu li:hover{
  background: #ebebeb;
  border-radius: 5px;
}


.actionLine{
  padding: 10px 0;
}
.tab-pane .container-fluid{
  margin-bottom:10px;
  margin-top:-1px;
}

/* order history */

.dropdown-menu > li > a:hover {
  cursor:pointer;
}

.testresults ul{
  padding: 0;
  margin: 0;
}

.testresults ul li{
  float: left;
  width: calc(100% - 20px);
  padding:0 10px;
  margin: 0 10px;

}

.testCenters{
  margin:0 0 60px 0;
  width:100%;
}
.popover-content {
  line-height: 18px;
  height:auto;
}

.form-group{
  position: relative;
}
.info-popup{
  position: absolute;
  top: 12px;
  font-size: 20px;
  color: #5bc0de;
  cursor: pointer;
  z-index:5;
}

div[ng-controller="DatePickerCtrl"] .info-popup {
  position: static;
  margin-left: 3px;
  align-self: center;
  /*top: -7px;*/
  /*left: 0;*/
}

div[ng-controller="DatePickerCtrl"] .top{
  height: 90px;
  line-height: 150px;
  color: black;
}
div[ng-controller="DatePickerCtrl"] .popover .arrow {
  display: none;
}

.info-popup:hover{
  color: #337ab7;
}
/* For iPad changes (Portrait Mode) */
@media (min-width:917px) and (max-width:800px){
  .buttons-mobile .btn{
    width:100%;
    margin-bottom:10px;
  }
  .buttons-mobile .positionfix{
    position:static !important;
  }
  .buttons-mobile .pull-left, .buttons-mobile .pull-right{
    float: none !important;
  }
  .product-gallery{
    text-align:center;
  }
  .product-gallery .img-responsive{
    display:inline-block;
  }
  .standartInfo {
    text-align:center;
  }
  .social{
    text-align:center;
  }
  .navbar-nav > li>a {
    padding: 0 10px;
  }
  .containerHeader {
    margin-top: 10px;
    .customStyle {
      top: 10px;
    }
  }
  .navbar-collapse{
    padding:0;
  }
  .top{
    line-height:50px;
    height: 70px;
  }
  .top .info{
    padding:15px 25px 0 25px;
    text-align:center;
  }
  .top .text-right{
    text-align:center;
  }
}
/* END */
/* FOR Smaller RESOLUTIONS */
@media (max-width: 1199px) {
  .checkbox {
    padding: 0 27px; }
  .payContainer {
    margin: 0 15px 0 10px;
  }
  .orderHistory button.btn{
    float:none !important;
    margin:10px 0 !important;
  }

  .searchResults .img-responsive {
    margin: 0 auto;
  }
  .buttons-mobile{
    margin-top:20px;
  }
  .standartInfo .social {
    font-size: 1em;
  }

  .cabinet{
    padding:0;
  }

  .checkout .nav-tabs>li{
    display: none;
  }
  .checkout .form-group{
    margin-left:-8px;
  }
  .nav-tabs>li.active{
    display: block;
    width:100%;
    border:none;
  }
  .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    border:none;
    border-bottom:1px solid #ccc;
  }

  .marketing{
    margin-top: -40px;
  }

  .tests-test {
    display: inline-table !important;
  }
  .buttons-mobile .btn{
    width:100%;
    margin-bottom:10px;
  }
  .buttons-mobile .positionfix{
    position:static !important;
  }
  .buttons-mobile .pull-left, .buttons-mobile .pull-right{
    float: none !important;
  }

  .product-functions{
    margin:20px 0;
  }

  .ngscroll-scrollbar-container {
    display: none;
  }
  .tests-window{
    overflow-x: auto !important;
  }
  .referral{
    padding:30px 0;
  }
  .contact .text-left, .contact .text-right{
    text-align:center;
  }
  #mdchat-start-convo-v1{
    z-index:14 !important;
    text-align: center;
  }
  .container-fluid{
    padding:0;
  }
  .sort-buttons .btn{
    font-size:12px;
  }
  .h1, h1 {
    font-size: 26px;
  }
  .well img {
    box-shadow: none;
    /* Changed for the testing (bug291), initial value is 50px*/
    height: 200px;
    width: auto;
  }
  /* NAVBAR NAVIGATION FOR MOBILE PHONES */
  .navbar-brand {
    font-size: 28px;
    color: #0aadbc;
  }
  .navbar .navbar-header {
    position: absolute;
    top: -157px;
    left: 15px;
  }
  .navbar-collapse {
    padding-bottom: 30px;
    color: white;
    width: 100%;
    height: 100%;
    background: #eee;
    z-index: 9;
    position: fixed;
    top: 0;
    left: -120%;
    display:block !important;
    opacity:0;
    -webkit-transition: all 250ms ease-out;
    -moz-transition: all 250ms ease-out;
    -ms-transition: all 250ms ease-out;
    -o-transition: all 250ms ease-out;
    transition: all 250ms ease-out;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.28);
  }
  .navbar-collapse.in{
    left:0;
    opacity:1;
  }
  .mobile-menu-logo{
    width: 170px;
    height: 70px;
    margin: 10px auto;
  }
  .mobile-menu-link{
    position: absolute;
    top: 22px;
    right: 10px;
    left: auto;
    color: #676767;
  }
  .categories .product{
    height: auto;
  }
  .breadcrumbs{display:none;}
  .cutWord{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 300px;
  }
  .contact{font-size:1em;}
  .mainvideo{margin:0;}
  // .row-eq-height {display:block !important;    }
  .text-right { text-align:center; }
  .dark-xs{ background-color:#282828; }
  .top{  height:auto;  line-height: 100px;  padding-top: 10px;  }
  .top img{ height: 70px; margin: 0 auto; }
  .navbar{height: 0;        min-height:0;}
  .carouselcontainer{  height: auto;  background:#102a41; padding:30px 0; }
  .carousel-inner{  height: auto;  }
  .carouselcontainer h1 {   font-size: 1em;   }
  .slide {height:auto;}
  .slide .texts {    width: 100%;    text-align: center;    margin:-40px 0 0 0 ;  }
  .slide .btn { float:none; margin-bottom:10px; }
  .carousel-indicators{display: none;}
  .social{text-align: center; }
  .social i{margin-right: 20px; font-size: 3em;}
  .main-navigation{ border:none; }
  .navbar-nav > li>a {
    line-height: 38px;
    padding: 10px 15px;
    text-transform: uppercase;
    color: #676767;
    font-weight: 700;
  }
  .navbar-nav>.active:after, .nav .open>a:after {
    height: 1px;
    bottom:-1px;
  }
  .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    color: #1fbfd1;
  }
  .nav>li>a:focus, .nav>li>a:hover {
    background-color: transparent;
  }
  .nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
    background-color: transparent;
    color: #1fbfd1;
  }
  .navbar .navbar-collapse .navbar-nav>.navbar-text, .navbar .navbar-collapse .navbar-nav>a.navbar-link, .navbar .navbar-collapse .navbar-nav>li, .navbar .navbar-collapse>.navbar-text, .navbar .navbar-collapse>a.navbar-link {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: #e6e6e6;
  }
  .tests-window{
    background: white;
  }
  .navbar .dropdown-menu li>a.dropdown-toggle {
    color: #676767;
  }
  .shoppingCart{
    width: 100%;
    padding: 3px;
  }
  .shoppingCart h1 {
    margin: 60px 0 40px 0;
    float: left;
  }
  .shoppingCart h1 small {
    font-size: 0.5em;
    float: left;
    margin-top: 25px;
  }
  .shoppingCart .cancel {
    top: 12px;
    right: auto;
    left: 19px;
  }
  .shoppingCart .btn{
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }
  .cancel{
    top: 12px;
    right: 13px;
  }
}
@media (max-width: 1279px){
  .tests-window {
    overflow-x: auto !important;
  }
  .ngscroll-scrollbar-container {
    display: none;
  }
  ol.carousel-indicators{
    display:none;
  }
  table.gmap {
    table-layout: fixed;
    width: 100%;
  }
}
@media screen and (max-width: 1600px){
  .w500 {
    max-width: 500px;
    /*width: 90%;*/
  }
}
@media (max-width: 1200px) {
  .navbar .navbar-nav {
    display: inline-block;
    float: none;
  }

  .navbar .navbar-collapse {
    text-align: center;
  }

  /* show ui-modal in the center screen */
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }

}


.btn-group .dropdown-menu{
  max-height: 200px;
  overflow: auto;
}

.reg-message{
  text-align: center;
}

.reg-message-success{
  background: #2e5c2e;
}

.reg-message-failed{
  background: #6b3030;
}

.ui-select-placeholder.text-muted {
  color: white
}
.ui-select-toggle a{
  display: none;
}
.clickable {
  cursor: pointer;
}

.uib-daypicker .btn-default {
  background-color: #fff;
}

.uib-daypicker .btn-default:hover {
  background-color: #99dbf8;
}

.uib-daypicker .btn-default.active {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.transactions-filter {
  padding-top: 15px;
}

i.pwnicon{
  width:17px;
  height:17px;
  background: url(assets/img/pwn.png) no-repeat;
  float: left;
  margin:3px 3px 0 0;
}
.appModalWindow .modal-dialog .password-alert {
  position: relative;
  top: -85px;
  width: 250px;
  right: 308px
}

.appModalWindow .mobileErrorView {
  display: none;
}

.appModalWindow .modal-dialog .password-alert li {
  list-style-type: none;
}

.appModalWindow .modal-dialog .password-alert li i {
  vertical-align: bottom
}

.appModalWindow .modal-dialog .password-alert ul {
  padding-left: 10px;
}

.appModalWindow .modal-dialog .password-alert label {
  text-transform: inherit;
}

.appModalWindow .modal-dialog .password-alert .material-icons {
  font-size: 14px;
}
.checkoutTooltipClass {
  font-size: 16px!important
}
@media (max-width: 1200px) {
  .autocompleteForm {
    width: 100% !important;
    mat-form-field {
      width: calc(100% - 350px) !important;
    }
  }
  .containerHeader {
    // margin-left: 0px;
    width: 100%;
  }
  .containerNavigation {
    // margin-left: 0px;
    width: 100%;
  }
}

@media (max-width: 917px) {
  .forms {
    padding: 24px;
  }
}

@media (max-width: 991px) {
  .appModalWindow .modal-dialog .password-alert {
    top: -122px;
    right: 263px;
  }
}

@media (max-width: 916px) {
  .appModalWindow .modal-dialog .password-alert {
    display: none;
  }
  .appModalWindow .mobileErrorView {
    display: block;
  }
  .autocompleteForm {
    mat-form-field {
      width: 80% !important;
    }
  }
}

@media (max-width: 1199px) {
  .top img {
    // margin-left: 36vw;
    margin: auto;
  }

  .top .img-responsive{
    display: block;
  }
  .headerImg {
    width: 100%;
    padding-bottom: 20px;
    // width: fit-content;
  }
}

@media (max-width:  768px) {
  .row-eq-height {
    display: block;
  }
}

// @media (max-width:  685px) {
//   .top img {
//     margin-left: 33vw;
//   }
// }

// @media (max-width: 565px) {
//   .top img {
//     margin-left: 28vw;
//   }
// }

// @media (max-width: 480px) {
//   .top img {
//     margin-left: 85%;
//   }
// }

// @media (max-width: 440px) {
//   .top img {
//     margin-left: 65%;
//   }
// }

// @media (max-width: 400px) {
//   .top img {
//     margin-left: 50%;
//   }
// }

// @media (max-width: 360px) {
//   .top img {
//     margin-left: 40%;
//   }
// }

.height51{
  height: 51px;
}

//html, body { height: 100%; }
//body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat-Regular'), url(./assets/fonts/montserrat-regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Work Sans Light'), local('WorkSans-Light'), url(./assets/fonts/worksans-light.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

html, body { height: 100%; }
body { font-family: "Work Sans",sans-serif;
  font-weight: 400;
  font-size: 16px; margin: 0; }

  .block-text p {
    color: #585858;
    font-family: Georgia;
    font-style: italic;
    line-height: 20px;
    height:74px;
    overflow:overlay
}
.person-text i {
  color: #fff;
  font-family: Georgia;
  font-size: 13px;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat-Regular'), url(./assets/fonts/montserrat-regular.woff2) format('woff2') !important;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Work Sans Light'), local('WorkSans-Light'), url(./assets/fonts/worksans-light.woff2) format('woff2') !important;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Work Sans'), local('WorkSans-Regular'), url(./assets/fonts/worksans.woff2) format('woff2') !important;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  color: #102a41 ;
}
@media (min-width: 1200px){
  .displayBelow768 {
    display: none;
  }
.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
.kKKhxL::before {
  position: absolute;
  display: block;
  z-index: 1;
  inset: 0px;
  background-color: #21AFBD;
  content: "";
}
* {
  outline: none;
  outline: 0;
}
html, body {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.example-full-width {
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #11AFBD;
  input{
    position: absolute;
    top: 25%;
    left: -1.5%;
  }
  .mat-form-field-label-wrapper {
    top: -45%;
    left: -1.5%;
    label {
      top: 4px;
      font-size: 17px;
    }
    span {
      font-weight: bold;
      color: #11AFBD;
    }
  }
  .mat-form-field-infix {
    border-top: 7px !important;
  }
}

.loginmodal {
  background: none;
}
.registrationmodal {
  background: none;
}



// NEW CALENDAR CSS
// mat-calendar {
//   background-color: #FFFFFF !important;
//   border-radius: 4px !important;
//   border: 1px solid #DDDDDD !important;
// }
// .popup.forms {
//   .birthday {
//     .mat-form-field {
//       width: 90%;
//       .mat-form-field-wrapper {
//         padding: 0px;
//         .mat-form-field-flex {
//           padding: 0px;
//           .mat-form-field-infix {
//             position: 0px;
//             padding: 0px;
//             border-top: 0px;
//             input {
//               border-top-right-radius: 0px;
//               border-bottom-right-radius: 0px;
//             }
//           }
//           .mat-icon-button {
//             border-radius: 4px;
//             border-top-left-radius: 0px;
//             border-bottom-left-radius: 0px;
//             border-top: 1px solid #CCECFC;
//             color:#487CAB;
//             height: 34px;
//             background-color: #CCECFC;
//             &:hover {
//               background-color: #99DBF8;
//             }
//             i {
//               padding-bottom: 5px !important;
//             }
//           }
//         }
//       }
//     }
//   }
// }
  @media (max-width: 625px) {
    .searchBtn {
      margin-top: 10px;
    }
  }
@media (max-width: 1199px) {
  .displayAbove768 {
    display: none;
  }
  .registrationmodal {
    max-width: none !important;
  }
  .testCenters {
    .searchBtn {
      left: calc((100% - 91px)/2);
      margin-left: 0px !important;
    }
    .errorClass {
      width: 100% !important;
      margin-top: 50px;
      margin-right: 70px;
    }
  }
}
  .mat-mdc-dialog-surface{
    overflow: inherit !important;
  }
app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  router-outlet + * {
    flex: 1 1 100%;
  }
}
}

*{
  --mat-select-placeholder-text-color: white !important;
  --mat-form-field-container-vertical-padding: 7px;
}

.mat-mdc-select-placeholder{
font-size: 14px;
}

.mat-progress-bar-fill::after {
  background-color: #2399DD;
}

.mat-progress-bar-buffer {
  background: #b3c3df;
}

.mat-progress-bar {
  border-radius: 2px;
}

@media print
{
  .printable { background-color: white !important; }
}


.promo-custom-field .mat-form-field-appearance-fill .mat-form-field-flex,
.promo-custom-field .mat-form-field-appearance-outline .mat-form-field-outline{
  // color: white!important;
  background: #1c629e!important;
  border: none!important;
  border-radius: 4px;
}
.promo-custom-field .mat-hint{
  color: white!important;
}

.orange{
  background-color: orange;
  text-align: center;
  font-size: 16px;
  padding: 4px 0px;
  font-weight: bold;
}

body .weglot-container {
  z-index: 1;
}
